import clsx from 'clsx'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useClickAway } from 'react-use'

// assets
import ImgAcquistion from 'assets/career-path/acquistion.png'
import ImgDesign from 'assets/career-path/design.png'
import ImgGame from 'assets/career-path/game.png'
import ImgNetwork from 'assets/career-path/network.png'
import ImgQA from 'assets/career-path/qa.png'
import { ReactComponent as IconArrowRight } from 'assets/svg/icon-arrow-right.svg'
import { ReactComponent as IconSearch } from 'assets/svg/icon-search.svg'

// components
import { ButtonSimple } from 'components/Button'
import { SelectDefault } from 'components/Select'
import CareerSlider from './CareerSlider'

const locations = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Remote',
        value: 'remote',
    },
    {
        label: 'Vietnam',
        value: 'vn',
    },
    {
        label: 'Singapore',
        value: 'singapore',
    },
    {
        label: 'USA',
        value: 'usa',
    },
]

const levels = [
    {
        label: 'Senior',
        value: 'senior',
    },
    {
        label: 'Junior',
        value: 'junior',
    },
    {
        label: 'Middle',
        value: 'middle',
    },
]

const postions = [
    {
        img: ImgDesign,
        location: 'Vietnam',
        level: 'Senior',
        title: 'UI/UX Design',
        href: '',
    },
    {
        img: ImgGame,
        location: 'Remote',
        level: 'Fresher',
        title: 'Gameplay Programmer',
        href: '',
    },
    {
        img: ImgDesign,
        location: 'Remote',
        level: 'Senior',
        title: 'UI Scripter',
        href: '',
    },
    {
        img: ImgQA,
        location: 'Remote',
        level: 'Senior',
        title: 'QA Engineer',
        href: 'Junior',
    },
    {
        img: ImgAcquistion,
        location: 'Singapore',
        level: 'Senior',
        title: 'Talent Acquisition Partner',
        href: '',
    },
    {
        img: ImgDesign,
        location: 'Vietnam',
        level: 'Senior',
        title: 'UI/UX Design',
        href: '',
    },
    {
        img: ImgGame,
        location: 'Remote',
        level: 'Fresher',
        title: 'Gameplay Programmer',
        href: '',
    },
    {
        img: ImgDesign,
        location: 'Remote',
        level: 'Senior',
        title: 'UI Scripter',
        href: '',
    },
    {
        img: ImgQA,
        location: 'Remote',
        level: 'Senior',
        title: 'QA Engineer',
        href: 'Junior',
    },
    {
        img: ImgAcquistion,
        location: 'Singapore',
        level: 'Senior',
        title: 'Talent Acquisition Partner',
        href: '',
    },
    {
        img: ImgDesign,
        location: 'Vietnam',
        level: 'Senior',
        title: 'UI/UX Design',
        href: '',
    },
    {
        img: ImgGame,
        location: 'Remote',
        level: 'Fresher',
        title: 'Gameplay Programmer',
        href: '',
    },
    {
        img: ImgDesign,
        location: 'Remote',
        level: 'Senior',
        title: 'UI Scripter',
        href: '',
    },
    {
        img: ImgQA,
        location: 'Remote',
        level: 'Senior',
        title: 'QA Engineer',
        href: 'Junior',
    },
    {
        img: ImgAcquistion,
        location: 'Singapore',
        level: 'Senior',
        title: 'Talent Acquisition Partner',
        href: '',
    },
    {
        img: ImgNetwork,
        location: 'Singapore',
        level: 'Senior',
        title: 'Talent Acquisition Partner',
        href: '',
    },
]

function Career() {
    const navigate = useNavigate()
    const [titleLocation, setTitleLocation] = React.useState('')
    const [titleLevel, setTitleLevel] = React.useState('')
    const [openSearch, setOpenSearch] = React.useState(false)
    // refs
    const searchRef = React.useRef(null)

    const onSelectedLocation = (option) => {
        setTitleLocation(option)
    }

    const onSelectedLevel = (option) => {
        setTitleLevel(option)
    }

    useClickAway(
        searchRef,
        () => {
            if (openSearch) {
                setOpenSearch(false)
            }
        },
        ['mousedown']
    )

    function handleGoToDetail() {
        navigate('/career-path/ui-ux-design')
    }

    return (
        <>
            <div className="container">
                <div className="careerLife d-flex items-center justify-between">
                    <div className="careerLife_info">
                        <h3 className="heading-3">Career at Ambros</h3>
                        <h5 className="heading-6 mt-1.5 mb-5">
                            You'll be part of an open, team-oriented and
                            results-based culture where your input is encouraged
                            and your success is rewarded.
                        </h5>
                        <ButtonSimple text="See more" />
                    </div>
                    <div className="career-slider">
                        <CareerSlider />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="career_heading">
                    <h3 className="heading-3">Openings</h3>
                </div>

                <div
                    ref={searchRef}
                    className={clsx('careerSearch', openSearch && 'openSearch')}
                >
                    <div className="careerSearch_position">
                        <IconSearch
                            className="iconSvg_white"
                            onClick={() => setOpenSearch(true)}
                        />
                        <input
                            type="text"
                            placeholder="Position"
                            className="heading-6"
                        />
                    </div>
                    <div className="careerSearch_select location">
                        <SelectDefault
                            placeholder="Location"
                            width="250px"
                            options={locations}
                            label={titleLocation.label}
                            selectedIndex={locations.findIndex(
                                (location) =>
                                    location.value === titleLocation.value
                            )}
                            onSelected={(option) => onSelectedLocation(option)}
                            renderOptions={(option) => <>{option.label}</>}
                        />
                    </div>
                    <div className="careerSearch_select">
                        <SelectDefault
                            placeholder="Level"
                            width="250px"
                            options={levels}
                            label={titleLevel.label}
                            selectedIndex={levels.findIndex(
                                (level) => level.value === titleLevel.value
                            )}
                            onSelected={(option) => onSelectedLevel(option)}
                            renderOptions={(option) => <>{option.label}</>}
                        />
                    </div>
                </div>

                <div className="careerOpens">
                    <div className="careerList">
                        {postions.map((postion, index) => (
                            <div
                                key={index}
                                className="careerBox careerBox_border careerBox_item round-16"
                                onClick={() => handleGoToDetail()}
                            >
                                <div className="careerBox_container">
                                    <img
                                        src={postion.img}
                                        alt={postion.title}
                                    />
                                    <div className="careerBox_info">
                                        <h5 className="heading-7 text-color-content-secondary">
                                            {postion.location}
                                        </h5>
                                        <h4 className="heading-12">
                                            {postion.title}
                                        </h4>
                                        <h6 className="heading-7 text-color-content-accent-primary">
                                            {postion.level}
                                        </h6>
                                    </div>
                                </div>
                                <div className="careerBox_arrow">
                                    <IconArrowRight className="iconSvg_white" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Career
