import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// components
import Layout from 'components/Layout'
import Loading from 'components/Loading'
import Career from 'pages/Career/Career'
import CareerDetail from 'pages/Career/CareerDetail'

// pages
const HomePage = lazy(() => import('pages/Home/Home'))
const LifeAtAmbros = lazy(() => import('pages/LifeAtAmbros'))

function App() {
    return (
        <div id="wrapper" className="wrapper">
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />
                        <Route path="/career-path" element={<Career />} />
                        <Route
                            path="/career-path/:slug"
                            element={<CareerDetail />}
                        />
                        <Route
                            path="/life-at-ambros"
                            element={<LifeAtAmbros />}
                        />
                        <Route path="/version" element={<p>0.0.1</p>} />
                        <Route path="/*" element={<Navigate to="/" />} />
                    </Route>
                </Routes>
            </Suspense>
        </div>
    )
}

export default App
