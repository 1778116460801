import Slider from 'react-slick'
import { useRef } from 'react'
// assets
import SliderTeam1 from 'assets/career-path/slider-team-1.png'
import ArrowLeftIcon from 'assets/svg/ic24-fill-chevron-left.svg'
import ArrowRightIcon from 'assets/svg/ic24-fill-chevron-right.svg'

const CareerSlider = ({ list = [] }) => {
    const sliderRef = useRef()
    const settings = {
        className: 'center',
        centerMode: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,
        arrows: false,
        dots: true,
        variableWidth: false,
        appendDots: (dots) => (
            <div className="list-dot-container">
                <NextArrow onClick={handleSlickPrev} />
                <ul className="list-dot">{dots}</ul>
                <PrevArrow onClick={handleSlickNext} />
            </div>
        ),
    }

    function handleSlickNext() {
        if (sliderRef.current) {
            sliderRef.current.slickNext()
        }
    }

    function handleSlickPrev() {
        if (sliderRef.current) {
            sliderRef.current.slickPrev()
        }
    }

    function NextArrow(props) {
        const { className, style, onClick } = props
        return (
            <li
                className={`${className} custom-arrow custom-arrow-next`}
                style={{ ...style }}
                onClick={onClick}
            >
                <img src={ArrowLeftIcon} alt="" />
            </li>
        )
    }

    function PrevArrow(props) {
        const { className, style, onClick } = props
        return (
            <li
                className={`${className} custom-arrow custom-arrow-prev`}
                style={{ ...style }}
                onClick={onClick}
            >
                <img src={ArrowRightIcon} alt="" />
            </li>
        )
    }
    return (
        <Slider {...settings} ref={sliderRef}>
            <div style={{ width: '100%' }} className="rounded-3xl">
                <div className="career-image-slider rounded-3xl">
                    <img src={SliderTeam1} alt="" className="rounded-3xl" />
                </div>
            </div>
            <div style={{ width: '100%' }}>
                <div className="career-image-slider rounded-3xl">
                    <img src={SliderTeam1} alt="" className="rounded-3xl" />
                </div>
            </div>
        </Slider>
    )
}

export default CareerSlider
