import React from 'react'
import clsx from 'clsx'
import { Portal } from 'react-portal'

// assets
import ImgDesign from 'assets/career-path/design.png'
import ImgGame from 'assets/career-path/game.png'
import ImgQA from 'assets/career-path/qa.png'
import { ReactComponent as IconClose } from 'assets/svg/icon-close.svg'

// components
import { ButtonSimple } from 'components/Button'
import FormApply from 'components/FormApply/FormApply'

const postions = [
    {
        img: ImgDesign,
        location: 'Vietnam',
        level: 'Senior',
        title: 'UI/UX Design',
        href: '',
    },
    {
        img: ImgGame,
        location: 'Remote',
        level: 'Fresher',
        title: 'Gameplay Programmer',
        href: '',
    },
    {
        img: ImgDesign,
        location: 'Remote',
        level: 'Senior',
        title: 'UI Scripter',
        href: '',
    },
    {
        img: ImgQA,
        location: 'Remote',
        level: 'Senior',
        title: 'QA Engineer',
        href: 'Junior',
    },
]

function CareerPathDetail() {
    const [openApplyForm, setOpenApplyForm] = React.useState(false)

    return (
        <div className="milestone">
            <div className="container">
                <div className="careerDetail_wrapper">
                    <div className="careerDetail_left">
                        <h6 className="heading-7 text-color-content-secondary">
                            Vietnam
                        </h6>
                        <h4 className="heading-10-black text-color-content-primary mt-1">
                            UI/UX Design
                        </h4>
                        <h5 className="heading-6 text-color-content-accent-primary mt-1">
                            Senior
                        </h5>

                        <div className="careerDetail_info heading-8 text-color-content-secondary">
                            Ambros group is seeking an area director for
                            Computer Vision to join Ambros LTD. The ideal
                            candidate should have an enthusiasm in advancing the
                            understanding of intelligence and a passion for
                            ambitious breakthroughs in the computer vision area.
                            To learn more about our lab, please visit
                            https://sail.sea.com/
                        </div>

                        <div className="careerDetail_content">
                            <h5>Job Description</h5>
                            <ul className="careerDetail_lead heading-8">
                                <li>
                                    Conduct fundamental research on Computer
                                    Vision, and/or Multi-Modality Artificial
                                    Intelligence, with long-term ambitious
                                    goals;
                                </li>
                                <li>
                                    Capabilities to shape and lead moon-shot
                                    projects;
                                </li>
                                <li>
                                    Publish influential research work on top
                                    conferences and journals;
                                </li>
                                <li>
                                    Collaborate on large projects that are
                                    strategically prioritized by the SAIL.
                                </li>
                            </ul>
                        </div>
                        <div className="careerDetail_content">
                            <h5>Job Requirements</h5>
                            <ul className="careerDetail_lead heading-8">
                                <li>
                                    Work experience in a university, industry or
                                    government lab(s), in a role with primary
                                    emphasis on AI research;
                                </li>
                                <li>
                                    Hold a Ph.D. degree in computer science,
                                    statistics, applied mathematics, data
                                    science or other related disciplines;
                                </li>
                                <li>
                                    First-author publications at peer-reviewed
                                    AI
                                </li>
                                <li>
                                    Collaborate on large projects that are
                                    strategically prioritized by the SAIL.
                                </li>
                            </ul>
                        </div>

                        <div className="careerDetail_content formApply_desktop">
                            <div className="careerDetail_form formApply">
                                <h5>Applying Information</h5>

                                <FormApply />
                            </div>

                            <div
                                className={clsx(
                                    'careerDetail_action mt-3 flex justify-end',
                                    openApplyForm && 'formApply_mobile'
                                )}
                            >
                                {openApplyForm && (
                                    <>
                                        <Portal>
                                            <div className="formApply_title">
                                                <span className="heading-8">
                                                    Applying Information
                                                </span>
                                                <div
                                                    className="formApply_title_iconClose"
                                                    onClick={() => {
                                                        setOpenApplyForm(false)
                                                        document.documentElement.style.overflow =
                                                            'auto'
                                                    }}
                                                >
                                                    <IconClose />
                                                </div>
                                            </div>
                                        </Portal>
                                        <div className="formChild">
                                            <FormApply />
                                        </div>
                                    </>
                                )}

                                <ButtonSimple
                                    text="Apply Now"
                                    minWidth="200px"
                                    onClick={() => {
                                        setOpenApplyForm(true)
                                        document.documentElement.style.overflow =
                                            'hidden'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="careerDetail_right">
                        <h6 className="heading-7 text-color-content-primary">
                            Related Positions
                        </h6>

                        {postions.map((postion, index) => (
                            <div
                                key={index}
                                className="careerBox careerBox_item border-bottom round-0"
                                onClick={() => {}}
                            >
                                <div className="careerBox_container">
                                    <img
                                        src={postion.img}
                                        alt={postion.title}
                                        width={48}
                                    />
                                    <div className="careerBox_info">
                                        <h5 className="heading-9 text-color-content-secondary">
                                            {postion.location}
                                        </h5>
                                        <h4 className="heading-7-bold">
                                            {postion.title}
                                        </h4>
                                        <h6 className="heading-9 text-color-content-accent-primary">
                                            {postion.level}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CareerPathDetail
